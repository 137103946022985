.sidebar {
  border-radius: 15px;
  background: $secondary-color;
  margin: 10px auto;
  padding: 10px;
  color: #fff;

  .booking-tool {
    color: #fff;
    text-align: center;
  }

  .review-widget-wrapper {
    max-width: 90%;
    margin: auto; display: block;
  }

  .youtube {
    height: 250px;
    border-radius: 10px;
  }

  &.sticky-sidebar {
    position: sticky;
    top: 55px;
  }

  .brakes-form {
    label {
      color: #fff;
    }

    button {
      background-color: #fff;
      color: #333;
    }
  }
}