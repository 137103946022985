span.photo-upload:before {
  content: "Upload file";
  left: 0;
  padding: 5px;
  background: $secondary-color;
  color: #fff;
  width: 130px;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.contact-form input[type=submit]
{
  border-radius: 5px;
}

span.photo-upload>input {
	display: none;
}

span.photo-upload
{
	margin-top: 10px;
	display: block;
}

.contact-form {
  background-color: $tertiary-color;
  width: 50%;
  border-radius: 25px;
  padding: 15px;

  .hidden {
    display: none;
  }

  input[type=submit] {
    border-radius: 5px;
    border: none;
    background-color: $secondary-color;
    color: #fff;
  }

  input[type=text],
  input[type=tel],
  textarea,
  input[type=email],
  select,
  label {
    border: 2px solid $secondary-color;
    border-radius: 5px;
    padding: 2px;
    width: 100%;
    max-width: 75%;
    font-weight: bold;
  }

  label {
   border: none;
   max-width: 100%;
  }
  
  p {
    text-align: center;
    margin-bottom: 0.25rem;
    color: $secondary-color;
  }
}

@media (max-width:991px) {
  .contact-form {
    input[type=text],
    input[type=tel],
    textarea,
    input[type=email],
    select,
    label {
      max-width: 90%;
    }
  }
}

@media (max-width:766px) {
  .contact-form {
    input[type=text],
    input[type=tel],
    textarea,
    input[type=email],
    select,
    label {
      max-width: 100%;
    }
  }
}

.consentcheck {
  position: relative;
  padding-left: 35px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    width: 0;
  }
  &:hover {
    input ~ .checkmark {
      background-color: #ccc;
    }
  }

  input:checked ~ .checkmark {
    background-color: $secondary-color;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid #000;
  margin: auto;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  &:after {
    content: "";
    position: absolute;
  }
}

.grecaptcha-badge {
  display: none !important;
}
